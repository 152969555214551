const config = {
  STRIPE_KEY: "pk_test_v1amvR35uoCNduJfkqGB8RLD",
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "us-east-1",
    BUCKET: "prod-notes-infra-s3-uploads4f6eb0fd-1838t5x17uk5u",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.serverless-stack.seed-demo.club/prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_2o9302crV",
    APP_CLIENT_ID: "6pp1doguafp4shqs18vn65aorf",
    IDENTITY_POOL_ID: "us-east-1:74a17149-6a33-43aa-a28f-c897a083f8e2",
  },
  // cognito: {
  //   REGION: "us-east-1",
  //   USER_POOL_ID: "us-east-1_BAculJKJt",
  //   APP_CLIENT_ID: "2bm3dr7gnjffmqf04865o4g1rn",
  //   APP_CLIENT_SECRET: "1j4abq1btma3boodec8m06qhakiosonji8icpvmm3durcvkg8g9"
  //   //IDENTITY_POOL_ID: "us-east-1:74a17149-6a33-43aa-a28f-c897a083f8e2",
  // },
};

export default config;
