import { useState } from "react";
import { Table, Tag, Space, Button,Descriptions,Input  } from "antd";
import { KeyOutlined,EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import MaskedInput from 'antd-mask-input';

const copyIcon = "Basic MmJtM2RyN2duamZmbXFmMDQ4NjVvNGcxcm46MWo0YWJxMWJ0bWEzYm9vZGVjOG0wNnFoYWtpb3NvbmppOGljcHZtbTNkdXJjdmtnOGc5"

const columns = [
  {
    title: "Product",
    dataIndex: "name",
    key: "name",
    width: 100,
  },
  {
    title: "Client Id",
    dataIndex: "clientId",
    key: "clientId",
    ellipsis: true,
  }, 
  {
    title: "Client Secret",
    dataIndex: "clientSecret",
    key: "clientSecret",
    ellipsis: true,
  }, 
  {
    title: "Access Key",
    dataIndex: "accessKey",
    key: "accessKey",
    ellipsis: true,
  }, 
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    render(item) {
        return(
        <Button style = {{marginBottom:'10px' }} type="primary" htmlType="submit">Unsubscribe</Button>
        );
    }
  }, 
];

const data = [
  {
    key: "1",
    name: "CD",
    clientId: "2bm3dr7gnjffmqf04865o4g1rn",
    clientSecret:"1j4abq1btma3boodec8m06qhakiosonji8icpvmm3durcvkg8g9",
    accessKey: "Basic MmJtM2RyN2duamZmbXFmMDQ4NjVvNGcxcm46MWo0YWJxMWJ0bWEzYm9vZGVjOG0wNnFoYWtpb3NvbmppOGljcHZtbTNkdXJjdmtnOGc5",
  },
];

// const Subscriptions = () => {
//   console.log("Request Access keys");
//   //setShowSecretsTable(true);
// };



const Subscriptions = () => {
  const [showSecretsTable, setShowSecretsTable]  = useState(false);
  return (
    <div>
        <div>
            <Button style = {{marginBottom:'10px' }} type="primary" htmlType="submit"> Subscribe Product </Button>
        </div>
      <div>
        <Table  columns={columns} dataSource={data} />
      </div>
    </div>
  );
};

export default Subscriptions;
