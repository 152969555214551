import {
  Collapse,
  Descriptions,
  Button,
  Table,
  Form,
  Input,
  Result,
} from "antd";
import React, { useState } from "react";

const { Panel } = Collapse;

const AUTHENTICATION_URL =
  "https://api-sandbox.auth.us-east-1.amazoncognito.com/oauth2/token";
const HEARTBEAT_URL =
  "https://api.sandbox.aws.cummins.com/v1/telematics/heartbeat";

  const FAULTCODE_URL =
  "https://api.sandbox.aws.cummins.com/v1/telematics/faultcode";


function callback(key) {
  console.log(key);
}

const request = `
  {"grant_type": "client_credentials"}
`;

const response = `
{
  "message": "Heart Beat Message Posted"
}
`;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 150,
  },
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
    ellipsis: true,
  },
];

const AuthStatusCodes = [
  {
    title: "Http StatusCode",
    dataIndex: "statusCode",
    key: "statusCode",
    width: 150,
  },
  {
    title: "Message",
    dataIndex: "msg",
    key: "msg",
    ellipsis: true,
  },
];

const AuthMsgs = [
  {
    key: "1",
    statusCode: "200",
    msg: "{\"access_token\":\"eyJraWQiOiJwR3dxMVc1eVlSdVVPbTd0UktkUFFkRG5paE5heDdpWTFNZjJ1OTRlWktJPSIsImFsZyI6IlJTMjU2In0\.eyJzdWIiOiIyYm0zZHI3Z25qZmZtcWYwNDg2NW80ZzFybiIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoidGVsZW1hdGljc1wvZmF1bHRjb2RlIHRlbGVtYXRpY3NcL2hlYXJ0YmVhdCB0ZWxlbWF0aWNzXC9sZWFybmluZ2FjY2VzcyIsImF1dGhfdGltZSI6MTYxODIyMjQ3OCwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfQkFjdWxKS0p0IiwiZXhwIjoxNjE4MjI2MDc4LCJpYXQiOjE2MTgyMjI0NzgsInZlcnNpb24iOjIsImp0aSI6IjM0ZGQ5OTU0LTdkODAtNDY1Zi05ODkwLTNkM2JhZjRmYjY4OCIsImNsaWVudF9pZCI6IjJibTNkcjdnbmpmZm1xZjA0ODY1bzRnMXJuIn0.cCsYzx85mUE5OVCYhKcEFHr0-Qm4w11Nn_cb-_rbqZQBOn1jC8_CwvXK7rozCtAJlk5f7DZ-7WF2IUV21zodx2nRsPBTVsvpFFBT9mTC8VnckacQubEEJY_JdGsSuA8ZFvQTNHJNiM3kJxj5gsir64PJji0Zb5L_OBizgBQp8wLGFaiAL9cySxp7kmQUkVxPlnM6Y1GfL363brKNwHfYnVtJgTiejmPWCzms_2OXO2wqY8FOulHQkRn8gDrRZutta9tCDi29hSdFKxtxnwFjd67-1iIsHo_mOV715hAfAeA_T8r6Q54e9yaCiil4ZquGSPg626xO-Ueo7ySWqRoe-w\",\"expires_in\":3600,\"token_type\":\"Bearer\"}",
  },
  {
    key: "2",
    statusCode: "400",
    msg: "Bad Request",
  },
  {
    key: "3",
    statusCode: "500",
    msg: "Internal Server Error",
  },
];

const HeartBeatmsgs = [
  {
    key: "1",
    statusCode: "200",
    msg: "HeartBeat Message Posted",
  },
  {
    key: "2",
    statusCode: "400",
    msg: "Bad Request",
  },
  {
    key: "3",
    statusCode: "500",
    msg: "Internal Server Error",
  },
];

const FaultCodeMsgs = [
  {
    key: "1",
    statusCode: "200",
    msg: "FaultCode Message Posted",
  },
  {
    key: "2",
    statusCode: "400",
    msg: "Bad Request",
  },
  {
    key: "3",
    statusCode: "500",
    msg: "Internal Server Error",
  },
];

const learningAccessCodes = [
  {
    key: "1",
    statusCode: "200",
    msg: "Learning Access Message Posted",
  },
  {
    key: "2",
    statusCode: "400",
    msg: "Bad Request",
  },
  {
    key: "3",
    statusCode: "500",
    msg: "Internal Server Error",
  },
];

const API = () => {
  const [showResponse, setShowResponse] = useState(false);
  const [keyResponse, setKeyResponse] = useState([]);
  const [accessKey, setAccessKey] = useState("");
  const [accessKeyLoading, setAccessKeyLoading] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [heartBeatResponse, setHeartBeatResponse] = useState("");
  const [faultcodeResponse, setFaultcodeResponse] = useState("");
  const [showHBResponse, setShowHBResponse] = useState(false);
  const [showFCResponse, setShowFCResponse] = useState(false);


  const onAccessKeyChange = (event) => {
    setAccessKey(event.target.value);
  };

  const setEnteredAccessToken = (event) => {
    setAccessToken(event.target.value);
  };

  const getAPIKeys = async () => {
    setAccessKeyLoading(false);
    console.log("Invoke Get API keys", accessKey);
    var formBody = "";
    var encodedKey = encodeURIComponent("grant_type");
    var encodedValue = encodeURIComponent("client_credentials");
    formBody = encodedKey + "=" + encodedValue;
    const response = await fetch(AUTHENTICATION_URL, {
      mode: "cors",
      headers: {
        Authorization: accessKey,
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept, Authorization",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      method: "post",
      body: formBody,
    });
    const data = await response.json();
    console.log(data);
    console.log(data.access_token);
    const dataSource = [
      {
        key: "1",
        name: "Access Token",
        value: data.access_token,
      },
      {
        key: "2",
        name: "Expiration",
        value: data.expires_in,
      },
      {
        key: "3",
        name: "Token Type",
        value: data.token_type,
      },
    ];
    setAccessKeyLoading(false);
    setKeyResponse(dataSource);
    setShowResponse(true);
  };

  const getHeartBeatResponse = async (event) => {
    console.log("Invoke Get API keys", event);
    console.log("AccessToken:", accessToken);
    var cors_api_host = 'cors-anywhere.herokuapp.com';
    var cors_api_url = 'https://' + cors_api_host + '/';
    const url = "https://api.sandbox.aws.cummins.com/v1/telematics/heartbeat";

    const response = await fetch(url, {
      mode: "cors",
      headers: {
        "Authorization": accessToken,
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept, Authorization",
        "Content-Type": "application/json",
      },
      method: "post",
    });
    console.log("Response", response);
    const data = await response.json();
    console.log("HeartBeat response data",data);
    const dataSource = [
      {
        key: "1",
        statusCode: response.status,
        msg: data.message,
      },
    ];
    setHeartBeatResponse(dataSource);
    setShowHBResponse(true);
  };

  const getFaultCodeResponse = async (event) => {
    console.log("Invoke Get API keys", event);
    console.log("AccessToken:", accessToken);
    var cors_api_host = 'cors-anywhere.herokuapp.com';
    var cors_api_url = 'https://' + cors_api_host + '/';
    const url = "https://api.sandbox.aws.cummins.com/v1/telematics/faultcode";

    const response = await fetch(url, {
      mode: "cors",
      headers: {
        "Authorization": accessToken,
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept, Authorization",
        "Content-Type": "application/json",
      },
      method: "post",
    });
    console.log("Response", response);
    const data = await response.json();
    console.log("HeartBeat response data",data);
    const dataSource = [
      {
        key: "1",
        statusCode: response.status,
        msg: data.message,
      },
    ];
    setFaultcodeResponse(dataSource);
    setShowFCResponse(true);
  };

  return (
    <div>
      <Collapse onChange={callback}>
        <Panel style={{ marginBottom: "6px" }} header="Authorization" key="1">
          <Descriptions title="API Spec" layout="horizontal">
            <Descriptions.Item label="URL" span={3}>
              {AUTHENTICATION_URL}
            </Descriptions.Item>
            <Descriptions.Item label="Method" span={3}>
              POST
            </Descriptions.Item>
            <Descriptions.Item label="Body" span={3}>
              {request}
            </Descriptions.Item>
            <Descriptions.Item label="Response" span={3}>
              <Table
                dataSource={AuthMsgs}
                columns={AuthStatusCodes}
                pagination={false}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Remarks" span={3}>
              Using client_id & client_secret generate base64 encoded valued and
              then pass it in the header for Authorization
            </Descriptions.Item>
          </Descriptions>
          <div>
            <Form name="control-ref">
              <Form.Item
                name="Access Key"
                label="Access Key"
                rules={[{ required: true }]}
                onChange={onAccessKeyChange}
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" onClick={getAPIKeys}>
                  Test API
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div style={{ display: showResponse ? "" : "none" }}>
            <Collapse defaultActiveKey={["1"]}>
              <Panel header="Response" key="1">
                <Table
                  dataSource={keyResponse}
                  columns={columns}
                  pagination={false}
                  loading={accessKeyLoading}
                />
              </Panel>
            </Collapse>
          </div>
        </Panel>
        <Panel style={{ marginBottom: "6px" }} header="Telematics API" key="2">
          <Collapse>
            <Panel header="HeartBeat API" key="1">
              <Descriptions
                title="HeartBeat API Spec"
                layout="horizontal"
              >
                <Descriptions.Item label="URL" span={3}>
                  {HEARTBEAT_URL}
                </Descriptions.Item>
                <Descriptions.Item label="Method" span={3}>
                  POST
                </Descriptions.Item>
                <Descriptions.Item label="Request Body" span={3}></Descriptions.Item>
                <Descriptions.Item label="Response" span={3}>
                  <Table
                    dataSource={HeartBeatmsgs}
                    columns={AuthStatusCodes}
                    pagination={false}
                  />
                </Descriptions.Item>
              </Descriptions>
              <div>
            <Form name="control-ref">
              <Form.Item
                name="Access Token"
                label="Access Token"
                rules={[{ required: true }]}
                onChange={setEnteredAccessToken}
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" onClick={getHeartBeatResponse}>
                  Test API
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div style={{ display: showHBResponse ? "" : "none" }}>
            <Collapse defaultActiveKey={["1"]}>
              <Panel header="API Response" key="1">
                <Table
                  dataSource={heartBeatResponse}
                  columns={AuthStatusCodes}
                  pagination={false}
                />
              </Panel>
            </Collapse>
          </div>
            </Panel>
            <Panel header="FaultCode API" key="2">
              <Descriptions
                title="FaultCode API Spec"
                layout="horizontal"
              >
                <Descriptions.Item label="URL" span={3}>
                  {FAULTCODE_URL}
                </Descriptions.Item>
                <Descriptions.Item label="Method" span={3}>
                  POST
                </Descriptions.Item>
                <Descriptions.Item label="Request Body" span={3}></Descriptions.Item>
                <Descriptions.Item label="Response" span={3}>
                  <Table
                    dataSource={FaultCodeMsgs}
                    columns={AuthStatusCodes}
                    pagination={false}
                  />
                </Descriptions.Item>
              </Descriptions>
              <div>
            <Form name="control-ref">
              <Form.Item
                name="Access Token"
                label="Access Token"
                rules={[{ required: true }]}
                onChange={setEnteredAccessToken}
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" onClick={getFaultCodeResponse}>
                  Test API
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div style={{ display: showFCResponse ? "" : "none" }}>
            <Collapse defaultActiveKey={["1"]}>
              <Panel header="API Response" key="1">
                <Table
                  dataSource={faultcodeResponse}
                  columns={AuthStatusCodes}
                  pagination={false}
                />
              </Panel>
            </Collapse>
          </div>
            </Panel>
            {/* <Panel header="Learning Access API" key="3">
              <Descriptions
                title="Learning Access API Spec"
                layout="horizontal"
              >
                <Descriptions.Item label="URL" span={3}>
                  {LEARNING_ACCESS_URL}
                </Descriptions.Item>
                <Descriptions.Item label="Method" span={3}>
                  POST
                </Descriptions.Item>
                <Descriptions.Item label="Request Body" span={3}></Descriptions.Item>
                <Descriptions.Item label="Response" span={3}>
                  <Table
                    dataSource={learningAccessCodes}
                    columns={AuthStatusCodes}
                    pagination={false}
                  />
                </Descriptions.Item>
              </Descriptions>
            </Panel> */}
          </Collapse>
        </Panel>
      </Collapse>
    </div>
  );
};

export default API;
